import React from "react"
import PropTypes from "prop-types"
import { connect as connectFela } from "react-fela"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import IntroBlock from 'components/IntroBlock'

const NotFoundPage = ({ styles, rules, pageContext, data: { header, settings, jobs } }) => {
  const image = { asset: header.childImageSharp }

  return <Layout
           pageColor={"#FFFFFF"}
           linkColor={colors.colorHuman200}
           title={'Page not found'}
           headerImage={image}
           settings={settings}
           jobs={jobs}
           country={pageContext.country}
         >
    <IntroBlock
      header
      extend={{
        styles: rules.header,
        copy: rules.copy,
        grid: rules.grid,
      }}
      title={'404'}
      copy={[
        {
          "_key": "",
          "_type": "block",
          "children": [
            {
              "_key": "",
              "_type": "span",
              "marks": [],
              "text": "The page you were hoping for might have been removed, had its name changed or is temporarily unavailable"
            }
          ],
          "markDefs": [],
          "style": "normal"
        }
      ]}
      link={{
        label: 'GO HOME',
        externalLink: {
          href: "/"
        }
      }}
      image={image}
      hidesidebar={false}
      color={colors.colorFlow50}
      pageColor={colors.colorConsole500}
      category={"LOST ???"} />
  </Layout>
}

const styles = () => ({
  header: {
    zIndex: 11,
    maxHeight: 1075,
    '& H1': {
      onAfter: {
        backgroundColor: 'white !important'
      }
    }
  },
  grid: {
    marginTop: '400px !important',
  },
  copy: {
    width: '80% !important',
    '@media(min-width: 768px)': {
      width: '50% !important',
    }
  },
  contentArea: {
    ...fluidValues({
      paddingTop: [133, 184],
    }),
    textAlign: 'center',
    zIndex: 1,

    phoneOnly: {
      position: 'relative'
    },
  }
})

NotFoundPage.defaultProps = {
  rules: {},
  styles: {},
}

NotFoundPage.propTypes = {
  styles: PropTypes.object,
  rules: PropTypes.object,
}

export default connectFela(styles)(NotFoundPage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    header: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width:  1440)
      }
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }
  }
`;
